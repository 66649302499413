import {http, routes} from "Figured/Assets/Modules";

/**
 * Convenience methods for fetching budget visibility over the network
 */
class BudgetVisibility {
    /**
     * @async
     *
     * @returns {Boolean}
     */
    async isNotVisible() {
        const onSuccess = response => {
            return response.data;
        };

        const onFailure = response => {
            throw new Error(
                `There was a problem fetching visibility status from the server. Got: "${response}".`
            );
        };

        return await this.constructor._fetchIsNotVisible(onSuccess, onFailure);
    }

    /**
     * @returns {Promise}
     */
    static _fetchIsNotVisible(onSuccess, onFailure) {
        const route = routes.get("budget.visibility.is_not_visible");

        return http.get(route)
            .then(onSuccess)
            .catch(onFailure);
    }
}

export default new BudgetVisibility();
